import React, { Component } from "react";
import Info from "../components/Info";
import PhotoSlider from "../components/PhotoSlider";
import firebase from "../utils/firebase";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import validator from "validator";

export default class Contact extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    sent: null,
  };

  submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { name, email, message } = this.state;
      const form = { name, email, message };
      const submitReq = firebase.functions().httpsCallable("contact");
      const result = await submitReq({ form });
      const data = result.data || {};
      const sent = data.status;
      this.setState({ sent });
    } catch (error) {
      console.log(error);
    }
  };

  changeHandler = (e) =>
    this.setState({ [`${e.target.name}`]: e.target.value });

  render() {
    console.log(this.state);
    return (
      <div className="contact">
        <PhotoSlider />
        <div className="home__info">
          <div className="constrain">
            <Info
              title="CONTACT"
              details={[
                "We are a Toronto Based Tent Rental Company.  We specialize in Weddings, Parities and Events. We offer Canopy Rentals and Creative Atmosphere ideas in Tents of many Shapes and Sizes. We operate also In. Mississauga, Oakville, Richmond Hill, York, Brampton. Etobicoke Barrie, and all other surrounding areas of the GTA.",
                "",
                "5800 Coopers Ave",
                "Mississauga, ON L4Z 2B9",
                "Canada",
              ]}
            />
            <div
              className="contact-form"
              style={{
                minWidth: "240px",
              }}
            >
              {this.state.sent ? (
                <Alert variant="success">
                  <Alert.Heading>Success!</Alert.Heading>
                  <p>Thank you for submitting your information!</p>
                  <hr />
                  <p className="mb-0">
                    A member of our team will contact you shortly. Please call
                    us if you would like to contact us sooner.
                  </p>
                </Alert>
              ) : (
                <Form>
                  <Form.Group controlId="name">
                    <Form.Control
                      onChange={this.changeHandler}
                      value={this.state.name}
                      name="name"
                      placeholder="Name..."
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Control
                      onChange={this.changeHandler}
                      value={this.state.email}
                      name="email"
                      placeholder="Email..."
                      type="email"
                    />
                    <Form.Text className="text-muted">
                      We'll contact you at this email.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      onChange={this.changeHandler}
                      value={this.state.message}
                      name="message"
                      placeholder="Approximate date of event and any details"
                      as="textarea"
                      rows="5"
                    />
                  </Form.Group>
                  <Button
                    disabled={
                      !this.state.email ||
                      this.state.email.length === 0 ||
                      !validator.isEmail(this.state.email)
                    }
                    block
                    onClick={this.submitHandler}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
