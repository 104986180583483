import React, { Component } from "react";
// import logo from "./logo.svg";
import { BrowserRouter as Router } from "react-router-dom";
import Context from "./utils/Context";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Footer from "./components/Footer";
import Routes from "./components/Routes";
import firebase from "./utils/firebase";

export default class App extends Component {
  state = {
    photosLoading: true,
    photos: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const storage = firebase.storage();
      const ref = storage.ref();
      const imagesRef = ref.child("www/gallery");
      const images = await imagesRef.getDownloadURL();
      const items = images.items || [];
      const promises = items.map((img) =>
        ref.child(img.fullPath).getDownloadURL()
      );
      const imgs = await Promise.all(promises);
      const photos = imgs.map((src) => ({
        src,
        width: 3,
        height: 2.1,
      }));
      this.setState({ photos, photosLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ photosLoading: false });
    }
  };
  render() {
    console.log(this.state);
    return (
      <Context.Provider value={this.state}>
        <Router>
          <div className="App">
            <Header />
            <main>
              <Routes />
            </main>
            <Footer />
          </div>
        </Router>
      </Context.Provider>
    );
  }
}
