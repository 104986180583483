import React from "react";

export default function Info(props) {
  return (
    <div className="home__info_details">
      <div className="home__info_details-title">
        <h2 className="home__info_title-text">{props.title}</h2>
      </div>
      <div className="home__info_details-text">
        <p>
          {props.details.map((i, n) => (
            <React.Fragment key={n}>
              {i}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
}
