import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
// import Photos from "../pages/Photos";
import Contact from "../pages/Contact";

export default function Routes() {
  return (
    <Switch>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/" render={Home}></Route>
    </Switch>
  );
}
