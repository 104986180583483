import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="App__header">
      <div className="header__logo">
        <Link to="/">
          <img
            alt="The Tent Renters - Logo"
            src={
              "https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Flogo.jpg?alt=media&token=98a4ff5d-daaf-43db-a9f8-7d2ff7e5e9fe"
            }
          />
        </Link>
      </div>
      <div className="header__nav-container">
        <Nav />
      </div>
    </header>
  );
}
