import React from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <nav>
      <ul>
        <Link to="contact" className="hover">
          CONTACT
        </Link>
      </ul>
    </nav>
  );
}
