// import firebase from "firebase";
// import firebase from "firebase/app";
// import "firebase/storage";
// import "firebase/auth/dist/index.cjs";
// import "firebase/functions/dist/index.cjs";
// require("firebase/functions/dist/index.cjs");
const firebase = require("firebase/app");
require("firebase/storage");
require("firebase/functions");

const firebaseConfig = {
  apiKey: "AIzaSyBWVOTpOFuFGOt3EcPRMC3vp-1yjVozT3Y",
  authDomain: "tent-renters.firebaseapp.com",
  databaseURL: "https://tent-renters.firebaseio.com",
  projectId: "tent-renters",
  storageBucket: "tent-renters.appspot.com",
  messagingSenderId: "316317269208",
  appId: "1:316317269208:web:349a2d75d33c9d489f6bad",
  measurementId: "G-LZTVSL45BW"
};

firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
