import React from "react";

export default function ContactInfo() {
  return (
    <div className="home__info_contact">
      <a href="tel:4162367368">
        <div className="home__info_phone-number" />
      </a>
      <a
        className="home__info_email hover"
        href="mailto:info@thetentrenters.com"
      >
        info@thetentrenters.com
      </a>
      <div className="home__info_social">
        <a
          href="https://www.facebook.com/TheTentRenters"
          rel="noopener noreferrer"
          target="_blank"
          className="home__info_social-link"
        >
          <img
            alt="Tent Renters on Facebook"
            src="https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Ffacebooklogo.png?alt=media&token=58a41d08-912b-4bb1-befe-945f2c983d89"
          />
        </a>
        <a
          href="https://twitter.com/thetentrenters"
          rel="noopener noreferrer"
          target="_blank"
          className="home__info_social-link"
        >
          <img
            alt="Tent Renters on Twitter"
            src="https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Ftwitterlogo.png?alt=media&token=a770a1fd-9b49-4ac5-acdf-7f71bb61dfc1"
          />
        </a>
      </div>
    </div>
  );
}
