import React from "react";
import Carousel from "nuka-carousel";

export default function PhotoSlider() {
  return (
    <div className="home__carousel">
      <Carousel
        autoplay
        cellAlign="center"
        dragging
        wrapAround
        heightMode="current"
      >
        <img
          style={{ objectFit: "cover" }}
          alt="The Tent Renters for a night tent"
          src="https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Fnight%20tent.jpg?alt=media&token=fd02bbba-3b0a-4562-9361-3310b3809e51"
        />
        <img
          style={{ objectFit: "cover" }}
          alt="The Tent Renters for a party tent"
          src="https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Fparty%20tent.png?alt=media&token=b35c308b-b6a6-4702-b834-031808b5cda2"
        />
        <img
          style={{ objectFit: "cover" }}
          alt="The Tent Renters for a lake tent party"
          src="https://firebasestorage.googleapis.com/v0/b/tent-renters.appspot.com/o/www%2Flake%20tent.jpg?alt=media&token=67898f3e-0012-45b6-ab20-2d546aa93431"
        />
      </Carousel>
    </div>
  );
}
