import React from "react";

export default function Footer() {
  return (
    <footer className="footer constrain">
      <div className="footer-logo">
        <div className="footer-logo__wordmark" />
        <div className="footer-logo__contact">
          <a href="tel:4162367368" className="footer-logo__phone hover">
            (416) 236-RENT
          </a>
          <a
            href="mailto:info@tentrenters.com"
            className="footer-logo__email hover"
          >
            info@thetentrenters.com
          </a>
        </div>
      </div>
      <div className="footer-info">
        <div className="footer-info__title">
          <p>We are a Toronto Based Tent Rental Company.</p>
        </div>
        <div className="footer-info__tagline">
          <p>
            We specialize in Weddings, Parities and Events. We offer Canopy
            Rentals and Creative Atmosphere ideas in Tents of many Shapes and
            Sizes.
          </p>
        </div>
      </div>
    </footer>
  );
}
