import React from "react";
import Info from "../components/Info";
import PhotoSlider from "../components/PhotoSlider";
import ContactInfo from "../components/ContactInfo";

export default function Home() {
  return (
    <div className="home">
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "fit-content",
        }}
        className="floating"
      >
        <div
          style={{
            width: "auto",
            position: "absolute",
            top: "20px",
            left: "calc(50% - 145px)",
            zIndex: "5",
            padding: "15px",
            textAlign: "center",
            borderRadius: "3px",
          }}
          className="call"
        >
          <a
            href="tel:4162367368"
            style={{
              color: "#ff9933",
              fontSize: "3rem",
              textShadow: "3px 3px 7px rgba(0, 0, 0, 0.3)",
              fontFamily: "sans-serif",
              marginBottom: "20px",
              fontWeight: 700,
            }}
          >
            CALL NOW
          </a>
        </div>
      </div>
      <PhotoSlider />
      <div className="home__info">
        <div className="constrain">
          <Info
            title="HOME"
            details={[
              "Welcome to the Tent Renters. The Tent Renters Inc. is a young, energetic company who takes pride in making your next event a success. With our dynamic inventory and our experienced, professional team we strive to create the atmosphere you desire. From your first call to your final event, the Tent Renters provide safe, dependable and quality service.",
            ]}
          />
          <ContactInfo />
        </div>
      </div>
    </div>
  );
}
